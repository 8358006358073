import '../index.css';
import { IoSearchOutline } from "react-icons/io5";


export default function ChatSearch({setFilterName}) {
    return (
        <div className='chat-search' style={{width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}>
            <input style={{width: '80%', height: '40px', borderRadius: '10px'}} placeholder="Search a chat" onChange={(e) => setFilterName(e.target.value)}/>
            <IoSearchOutline style={{width: '20px', height: '20px', color: 'gray', marginLeft: '-30px', zIndex: '999'}}/>
        </div>
    )
}