import './App.css';
import Chat from './pages/Chat.js';
import Signin from './pages/Signin.js';
import Signup from './pages/Signup.js';
import Account from './pages/Account.js';
import { Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import React from 'react';


export const MainUserContext = React.createContext(null);


function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState(2);

  return (
    <>
    <MainUserContext.Provider value={{ userId, setUserId, email, setEmail, loggedIn, setLoggedIn }}>
      <Routes>
          <Route path="*" element={<Chat/>} />
          <Route path="/signin" element={<Signin/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/account" element={<Account/>} />
      </Routes>
    </MainUserContext.Provider>
    </>
  );
}

export default App;
