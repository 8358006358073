import Input from '@mui/joy/Input';
import { LuAward } from "react-icons/lu";
import { LuArrowRight } from "react-icons/lu";
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import React from 'react';
import { CircularProgress } from "@mui/joy";
import SourcesFab from './SourcesFab';


import axios from 'axios';
import { UserContext } from '../pages/Chat';
import { Typography } from '@mui/material';


export default function ChatInput( {enableChatInput} ) {
    const [chatInput, setChatInput] = React.useState('');
    const { messages, setMessages } = React.useContext(UserContext);
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { messageIsLoadingDetails, setMessageIsLoadingDetails } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);
    const { openSources, setOpenSources } = React.useContext(UserContext);

    const { sources, setSources } = React.useContext(UserContext);

    const areSourcesEmpty = () => {
        return !sources.length
    }

    React.useEffect(() => {
        if (messageIsLoading) {
            // Look for backend task
            axios.get(process.env.REACT_APP_API_URL + '/api/ia/tasks/' + currentChatId)
                .then((response) => {
                    setMessageIsLoadingDetails(response.data.progress + " - " + response.data.status);
                })
                .catch((error) => {
                    console.error('Error fetching task');
                });
        }
    },);

    const handleSubmit = (e) => {
        e.preventDefault();
        messages.push({role_id: 1, content: chatInput});
        setMessageIsLoading(true);
        axios.post(process.env.REACT_APP_API_URL + '/api/messages', {
            conversation_id: currentChatId,
            role_id: 1,
            message: chatInput
        }).then(response => {
            axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + currentChatId)
            .then(response => {
                    axios.post(process.env.REACT_APP_API_URL + '/api/ia/call', {
                        query: chatInput,
                        chat_history: messages,
                        conversation_id: currentChatId
                    }).then(response => {
                        setChatInput('');
                        axios.post(process.env.REACT_APP_API_URL + '/api/messages', {
                            conversation_id: currentChatId,
                            role_id: 2,
                            message: response.data.text
                        }).then(response => {
                            axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + currentChatId)
                            .then(response => {
                                setMessages(response.data);
                                setMessageIsLoading(false);
                                setMessageIsLoadingDetails("")
                            })
                                .catch(error => {
                                    console.error('Error fetching messages:', error);
                                });
                            }).catch(error => {
                                console.error('Error creating message:', error);
                            });
                        });
                        
                    })
                .catch(error => {
                    console.error('Error fetching messages:', error);
                });
            
        }).catch(error => {
            console.error('Error creating message:', error);
            setMessageIsLoading(false);
            setMessageIsLoadingDetails("")
        });
    }

    return (
        <>
        { enableChatInput &&
            <div className="chat-input-containter">
            <form
            onSubmit={handleSubmit}
             style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center', gap: '1rem'}}>
                <Input required
                    value={chatInput}
                    onChange={(e) => setChatInput(e.target.value)}
                    startDecorator={<LuAward/>}
                    endDecorator={
                        <IconButton variant='solid'
                            sx={{
                                backgroundColor: 'orange',
                                ":hover": {
                                    backgroundColor: '#ff8c00'
                                }
                            }}
                            size='md'
                            type='submit'
                            disabled={messageIsLoading}
                            style={{marginRight: '0.2rem'}}>
                                {messageIsLoading ? 
                                <CircularProgress 
                                variant='plain'
                                thickness={1}
                                size="sm"
                                /> : <LuArrowRight />}
                        </IconButton>
                    }
                    sx={{
                        borderRadius: '0.7rem',
                        '--Input-focusedThickness': '0',
                        boxShadow: '0px 10px 17px 0px rgba(0,0,0,0.2)'
                    }}
                    variant="outline" placeholder="Type a message" className='chat-input'
                    disabled={messageIsLoading}
                />
                <FormHelperText>
                    
                </FormHelperText>
            </form>
            {!areSourcesEmpty() && <SourcesFab setOpenSources={setOpenSources} />}
        </div>
        }
        </>
    )
}