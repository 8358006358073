import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import SparkAI_logo from '../assets/SparkAI_logo_black.png';
import '../App.css';
import ChatList from './ChatButton';
import ChatSearch from './ChatSearch';
import { LuSettings2 } from "react-icons/lu";
import { AiOutlinePlus } from "react-icons/ai";
import '../index.css';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Avatar from '@mui/joy/Avatar';
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";

import { UserContext } from '../pages/Chat';

import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import MenuButton from '@mui/joy/MenuButton';
import Dropdown from '@mui/joy/Dropdown';
import { Stack } from '@mui/joy';

import { MainUserContext } from '../App';

import { useNavigate } from 'react-router-dom';


function ProfileMenu() {

    const { setLoggedIn } = React.useContext(MainUserContext);

    const logOut = () => {
        setLoggedIn(false);
    }
    const navigate = useNavigate();

    return (
        <Dropdown>
        <MenuButton variant="plain" sx={{borderRadius: "10rem", padding: '0.2rem'}}>
            <Avatar variant='solid' sx={{cursor: 'pointer', backgroundColor: 'orange'}}
            >
                YOU
            </Avatar>
        </MenuButton>
        <Menu placement="bottom-end" sx={{zIndex: 9999}}>
            <MenuItem onClick={() => navigate('/account')}>
            <ListItemDecorator>
                <FaUserCircle />
            </ListItemDecorator>{' '}
            My account
            </MenuItem>
            <ListDivider />
            <MenuItem variant="soft" color="danger" onClick={() => logOut()}>
            <ListItemDecorator sx={{ color: 'inherit' }}>
                <MdLogout />
            </ListItemDecorator>{' '}
            Log out
            </MenuItem>
        </Menu>
        </Dropdown>
    )
}

function DrawerHeader({ drawerSize }) {
    return (
        <div className='drawer-component' style={{ width: drawerSize, height:'7%', minHeight: '50px', borderRadius: '25px', paddingBottom: '0.5rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', paddingLeft: '20px', gap: '15px', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center', alignItems: 'center', gap: '1rem'}}>
                    <img src={SparkAI_logo} alt="SparkAI_logo" style={{ width: '35px', height: '35px'}} />
                    <h3>My chats</h3>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignContent: 'center', alignItems: 'center', gap: '1rem', paddingRight: '2rem'}}>
                    <ProfileMenu/>
                </div>
            </div>
        </div>
    );
}

function NewChatButton() {

    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);    
    const { messages, setMessages } = React.useContext(UserContext);

    return (
        <>
        <div className='new-chat-button' style={{width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'flex-end', alignItems: 'flex-end', margin: '1rem 0', height: 'auto'}}>
            <Button 
                endDecorator={<AiOutlinePlus style={{width: '20px', height: '20px', color: 'var(--new-chat-button-color)'}}/>}
                className='new-chat'
                sx= {{
                        width: '80%', height: '3rem', borderRadius: '0.5rem', color: 'var(--new-chat-button-color)', border: 'none', cursor: 'pointer', textAlign: 'left', paddingLeft: '1rem', backgroundColor: 'var(--new-chat-button-background-color)', ":hover": {backgroundColor: 'var(--new-chat-button-background-color-hover)', display: 'flex'}
                    }}
                onClick={() => {
                    setMessages([]);
                }}
                disabled={messageIsLoading}
                >
                <Typography sx={{color: 'var(--new-chat-button-color)'}}>
                    New chat
                </Typography>
            </Button>
        </div>
        </>
    );
}

function DrawerBody({ drawerSize, setCurrentChatName }) {
    const [filterName, setFilterName] = React.useState('');
    return (
        <div className='drawer-component' style={{ width: drawerSize, height: '100%', minHeight: '350px', borderRadius: '25px', display: 'flex', flexDirection: 'column' }}>
            <ChatSearch setFilterName={setFilterName}/>
            <Stack direction='column' spacing={1} sx={{flexGrow: 1, justifyContent: 'space-between', alignItems: 'stretch', width: '100%'}}>
                <div style={{ flexGrow: 1, width: '100%', maxHeight: '425px', overflowY: 'auto', overflowX: 'hidden'}}>
                <ChatList
                    filterName={filterName} setCurrentChatName={setCurrentChatName}/>
                </div>
                <NewChatButton />
            </Stack>
        </div>
    );
}

export default function PersistentDrawer({ isOpen, drawerSize, drawerMargin, setCurrentChatName, setLoggedIn }) {
    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={isOpen}
            sx={{
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    boxShadow: 'none', // Remove the shadow
                    border: 'none', // Remove the border if there's any
                    backgroundColor: 'transparent', // Transparent background
                },
            }}
        >
            <div style={{ width: drawerSize + 5, display: 'flex', flexDirection: 'column', gap: '10px', backgroundColor: 'transparent', height:'100vh', borderRadius: '25px', margin: drawerMargin}}>
                <DrawerHeader drawerSize={drawerSize} setLoggedIn={setLoggedIn}/>
                <DrawerBody drawerSize={drawerSize} setCurrentChatName={setCurrentChatName}/>
            </div>
        </Drawer>
    );
}