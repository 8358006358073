import * as React from 'react';

import { FiMessageSquare } from "react-icons/fi";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import ListDivider from '@mui/joy/ListDivider';
import Input from '@mui/joy/Input';
import { Box, Stack } from '@mui/joy';

import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

import { CgRename } from "react-icons/cg";
import { MdDeleteOutline } from "react-icons/md";

import axios from 'axios';

import '../index.css';

import { UserContext } from '../pages/Chat';
import { MainUserContext } from '../App';


function RenameChatModal({isOpen, setIsOpen, chatId}) {

    const [newChatName, setNewChatName] = React.useState('');
    
    const renameChat = ( newName ) => {
        axios.post(process.env.REACT_APP_API_URL + '/api/chats/rename', {
            conversation_id: chatId,
            conversation_name: newName
        }).then(response => {
        }).catch(error => {
        });
    }

    return (
        <React.Fragment>
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Sheet
            variant="outlined"
            sx={{ width: 800, borderRadius: 'md', p: 3, boxShadow: 'lg' }}
            >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                sx={{ fontWeight: 'lg', mb: 1, textAlign: 'center' }}
            >
                Rename chat
            </Typography>
            <form
                onSubmit={(e) => {
                e.preventDefault();
                renameChat(newChatName);
                setNewChatName('');
                setIsOpen(false);
            }}>
                <Input required
                    placeholder="New chat name"
                    value={newChatName}
                    onChange={(e) => setNewChatName(e.target.value)}
                    sx={{ width: '100%', mb: 2 }}
                />
            </form>
            </Sheet>
        </Modal>
        </React.Fragment>
    );
}

function ChatButtonMenu( {setIsOpen, chatId} ) {

    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const deleteChat = () => {
        axios.post(process.env.REACT_APP_API_URL + '/api/chats/delete', {
            conversation_id: chatId
        }).then(response => {
        }).catch(error => {
        });
    }

    return (
        <Dropdown>
            <MenuButton variant="plain">
                <HiOutlineDotsHorizontal style={{width: '20px', height: '20px', color: 'black', cursor: 'pointer'}}/>
            </MenuButton>
            <Menu sx={{zIndex: '9999'}}>
                <MenuItem onClick={() => setIsOpen(true)} disabled={messageIsLoading}>
                    <ListItemDecorator>
                        <CgRename/>
                    </ListItemDecorator>
                    Rename
                </MenuItem>
                <ListDivider/>
                <MenuItem variant='soft' color='danger' disabled={messageIsLoading}
                    onClick={() => deleteChat()}>
                    <ListItemDecorator>
                        <MdDeleteOutline/>
                    </ListItemDecorator>
                    Delete
                </MenuItem>
            </Menu>
        </Dropdown>
    )
}

function SnackbarWithDecorators({alertOpen, setAlertOpen}) {
  
    return (
      <React.Fragment>
        <Snackbar
          variant="soft"
          autoHideDuration={2000}
          color="success"
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
          endDecorator={
            <Button
              onClick={() => setAlertOpen(false)}
              size="sm"
              variant="soft"
              color="success"
            >
              Dismiss
            </Button>
          }
        >
          Chat selected successfully!
        </Snackbar>
      </React.Fragment>
    );
  }

function ChatButton({chatName, chatId, setCurrentChatName}) {
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { currentChatId, setCurrentChatId } = React.useContext(UserContext);
    const [ buttonStyle, setButtonStyle ] = React.useState({});
    
    React.useEffect(() => {
        if (chatId === currentChatId) {
            setButtonStyle({backgroundColor: 'lightgrey'});
        } else {
            setButtonStyle({});
        }
    }, [currentChatId]);

    const setChatName = (newChatName, newChatId) => {
        if (newChatId === currentChatId) {
            return;
        }
        setCurrentChatName(newChatName);
        setCurrentChatId(newChatId);
        setAlertOpen(true);
    }
    return (
        <>
        <button className="chat-button" style={buttonStyle} onClick={() => setChatName(chatName, chatId)} disabled={messageIsLoading}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center', justifyContent: 'flex-start', width: '100%'}}>
                <FiMessageSquare style={{width: '20px', height: '20px', color: 'black'}}/>
                <Box sx={{maxWidth: '100px'}}>
                <p
                    style={{flexGrow: 1, overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                    >{chatName}</p>
                </Box>
            </Stack>
            <ChatButtonMenu setIsOpen={setIsOpen} chatId={chatId}/>
            <RenameChatModal isOpen={isOpen} setIsOpen={setIsOpen} chatId={chatId}/>
        </button>
        <SnackbarWithDecorators alertOpen={alertOpen} setAlertOpen={setAlertOpen}/>
        </>
    )
}

export default function ChatList({filterName, setCurrentChatName}) {

    const { chats, setChats } = React.useContext(UserContext);
    const { currentChatId, setCurrentChatId } = React.useContext(UserContext);
    const { userId, setUserId } = React.useContext(MainUserContext);


    
    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/chat/' + userId)
        .then(response => {
            setChats(response.data);
        })
        .catch(error => {
            console.error(error);
        });
    });

    return (
        <div className='chat-list'>
            {chats.map(function(chat){
                if (chat.conversation_name.toString().toLowerCase().includes(filterName.toString().toLowerCase())){
                    return (
                        <ChatButton key={chat.conversation_id} chatName={chat.conversation_name} chatId={chat.conversation_id} setCurrentChatName={setCurrentChatName} setCurrentChatId={setCurrentChatId}/>
                    )
                }
            })}
        </div>
    )
}