import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import Stack from '@mui/joy/Stack';
import { FaFire } from "react-icons/fa6";

import { UserContext } from '../pages/Chat';
import { Badge } from '@mui/material';


function SourceComponent({ source, n }) {
    return (
        <Accordion sx={{ padding: 3 }}>
            <AccordionSummary>
                <Stack 
                    sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', pr: 7 }}
                    direction="row" spacing={10}>
                    <Typography level="title-md">{source.title}</Typography>
                    { source.is_relevant ?
                        <Badge badgeContent={
                            <Stack direction="row" spacing={1} alignItems="center" sx={{
                                backgroundColor: 'orange',
                                color: 'white',
                                borderRadius: 'lg',
                                padding: '0px 3px',
                            }} >
                                <FaFire />
                                <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap', maxWidth: '200px' }}>
                                    Very relevant
                                </Typography>
                            </Stack>
                        }/> : <></>
                    } 
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
            <Stack direction="column" spacing={2}>
                <Typography level="body-xs" color="text.secondary">
                    {source.authors}
                </Typography>
                <Typography variant="body1" color="text.secondary" textAlign="justify" sx={{textIndent: 20, lineHeight: '27px'}}>
                    {source.abstract ? source.abstract : "No abstract available, sorry for the inconvenience!"}
                </Typography>
                <Button
                onClick={() => window.open(source.url, '_blank')}
                variant="outlined" sx={{ width: '100%' }}>
                    Open paper
                </Button>
            </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

function SourcesList() {
    const { sources, setSources } = React.useContext(UserContext);

    return (
        <AccordionGroup sx={{ maxWidth: 'inherit' }}>
            
            {sources.map((source, index) => {
                return (
                    <SourceComponent key={index} source={source} n={index} />
                );
            })}
        </AccordionGroup>
    );
}

export default function BasicModal() {
    const { openSources, setOpenSources } = React.useContext(UserContext);

    return (
        <React.Fragment>
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={openSources}
            onClose={() => setOpenSources(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Sheet
            variant="outlined"
            sx={{ maxWidth: 1300, borderRadius: 'md', p: 3, boxShadow: 'lg', overflow: 'auto', maxHeight: '90vh' }}
            >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <SourcesList />
            </Sheet>
        </Modal>
        </React.Fragment>
    );
}