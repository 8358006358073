import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import { MdOutlineArticle } from "react-icons/md";
import { orange } from '@mui/material/colors'; // Import Material UI color
import { motion } from "framer-motion"

import { UserContext } from '../pages/Chat';


export default function SourcesFab() {
    const { openSources, setOpenSources } = React.useContext(UserContext);

    return (
        <Box sx={{ '& > :not(style)': { m: 1 }, position: 'fixed', bottom: 64, right: 64, zIndex: 9999999 }}>
            <motion.div 
                initial={{ opacity: 0, scale: 0 }} // Start with opacity 0 and scale 0
                animate={{ opacity: 1, scale: 1 }} // Animate to opacity 1 and scale 1
                transition={{
                type: 'spring',      // Use a spring-based animation for a bounce effect
                stiffness: 150,      // Controls the bounce strength
                damping: 20,         // Controls the "bounciness"
                duration: 0.8,       // Optional: duration of the animation
                }}
            >
                <Fab
                disabled={openSources}
                onClick={() => setOpenSources(true)}
                color="primary" aria-label="add" sx={{ width: '70px', height: '70px', backgroundColor: orange[300], '&:hover': { backgroundColor: orange[600] } }}>
                    <MdOutlineArticle size={30}/>
                </Fab>
            </motion.div>
        </Box>
    );
}