import { Badge } from "@mui/material";
import { BiArrowToLeft } from "react-icons/bi";
import { BiArrowToRight } from "react-icons/bi";
import '../index.css';
import SparkAI_logo from '../assets/SparkAI_logo_black.png';
import Typography from "@mui/joy/Typography";
import { useMediaQuery } from '@mui/material';
import { Stack } from "@mui/joy";

export default function TopHeader({isOpen, toggleDrawer, currentChatName}){

    const isSmallScreen = useMediaQuery('(max-width: 1000px)');

    return (
        <div style={{display: 'flex', flexDirection: 'row', height: '5rem', justifyContent: 'flex-start', alignItems: 'center', gap: '2rem', position: 'fixed', width: '100%', boxShadow: 'inset 0 40px 50px -10px rgba(255, 255, 255, 0.6)'}}>
            {isOpen ? <BiArrowToLeft className='drawer-button' onClick={toggleDrawer}/> : <BiArrowToRight className="drawer-button" onClick={toggleDrawer}/>}
            <h3>{currentChatName}</h3>
            <Badge className="model-badge">sparkai-1.0-beta</Badge>
            {!isSmallScreen &&
                <Stack direction="column" spacing={4} sx={{flexGrow: 1, width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center', right: '3rem', position: 'fixed'}}>
                    <img src={SparkAI_logo} alt="profile" style={{ width: '35px', height: '35px'}} />
                    <Typography level="h3">SparkAI Beta</Typography>
                </div>
                <Typography level="body-xs" color="text.secondary" style={{right: '3rem', position: 'fixed'}}>By EderSpark</Typography>
                </Stack>
            }
        </div>
    )
}