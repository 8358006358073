import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import React, { useState } from 'react';
import { ReactComponent as Logo} from '../assets/logo-no-slogan-no-background.svg';
import { FaUser } from "react-icons/fa6";
import { RiLockPasswordFill } from "react-icons/ri";
import Checkbox from '@mui/joy/Checkbox';
import Link from '@mui/joy/Link';
import Card from '@mui/joy/Card';
import axios from 'axios';
import { useEffect } from 'react';
import { MainUserContext } from '../App';

import { Helmet } from 'react-helmet';

import { useNavigate } from 'react-router-dom';


export default function Signin() {

  const { userId, setUserId, email, setEmail, loggedIn, setLoggedIn } = React.useContext(MainUserContext);

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn) {
        navigate('/chat');
    }
  }, [loggedIn]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    // You can add authentication logic here
    axios.post(process.env.REACT_APP_API_URL + '/api/auth/verify', {
      mail: email,
      password: password,
    }).then(response => {
      if (response.data.auth) {
        setLoggedIn(true);
        setUserId(response.data.result[0].user_id);
        navigate('/');
      } else {
        setPasswordError('Invalid email or password');
      }
    }).catch(error => {
      console.error(error);
      setEmailError('An error occurred');
      return;
    });
  }


  return (
    <main style={styles.main_container}>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <Logo style={styles.logo} />
      <Card variant='soft' sx={styles.container}>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputGroup}>
            <label htmlFor="email"></label>
            <Input
              sx={{ backgroundColor: 'white' }}
              startDecorator={<FaUser size={15} />}
              variant='soft'
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
              required
            />
            <text style={{ color: 'red', fontSize: '0.8rem' }}>{emailError}</text>
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="password"></label>
            <Input
              sx={{ backgroundColor: 'white' }}
              startDecorator={<RiLockPasswordFill size={15} />}
              variant='soft'
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Password'
              required
            />
            <text style={{ color: 'red', fontSize: '0.8rem' }}>{passwordError}</text>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '0.5rem' }}>
              <div style={styles.show_pass_container}>
                <Checkbox sx={styles.pass_checkbox} onChange={handleShowPassword} />
                <label htmlFor="password">Show password</label>
              </div>
              <Link href="/forgot-password">Forgot password?</Link>
            </div>
          </div>
          <Button type="submit" style={styles.button}>Login</Button>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '0.5rem' }}>
            <text
              style={{ marginRight: '0.5rem' }}
            >Don't have an account?</text>
            <Link href="/signup">Create account</Link>
          </div>
        </form>
      </Card>
    </main>
  );
}

const styles = {
  main_container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    marginTop: '-3rem',
  },
  container: {
    width: '22rem',
    margin: '0',
    padding: '2rem',
    borderRadius: '0.8rem',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    backgroundColor: '#dfffe3bb',

  },
  logo: {
    width: '15rem',
    height: '15rem',
    margin: '0',
    marginBottom: '-5rem',
  },
  title: {
    textAlign: 'center',
  },
  show_pass_container: {
    display: 'flex',
    alignItems: 'center',
  },
  pass_checkbox: {
    padding: '0.5rem 0',
    paddingRight: '0.5rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroup: {
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
};
