import { Box, Card, Input, Skeleton, Stack, Typography } from '@mui/joy';
import '../index.css';
import * as React from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown'
import sparkaiLogo from '../assets/SparkAI_logo_black.png';
import { MdOutlineBrowserUpdated } from "react-icons/md";
import { VscReferences } from "react-icons/vsc";
import { BiConversation } from "react-icons/bi";
import { CircularProgress } from "@mui/joy";


import { UserContext } from '../pages/Chat';

import '../index.css';
import { MainUserContext } from '../App';


function ChatMessage({ role_id, content, lastSecondsElapsed }) {
    const displayRole = role_id === 1 ? "You" : "SparkAI Beta";
    const roleClass = role_id === 1 ? "message-user" : "message-bot";
    const containerClass = role_id === 1 ? "message-container-user" : "message-container-bot";
    const showTime = role_id === 2 && lastSecondsElapsed;
    return (
        <div className={containerClass}>
            {showTime ?
            <Stack direction='row' spacing={2} sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
            <Typography variant="body1" style={{fontWeight: 'bold'}}>{displayRole}</Typography>
            <Typography level='body-sm' sx={{whiteSpace: 'nowrap'}}> {lastSecondsElapsed.toFixed(1)} secs</Typography>
            </Stack> :
            <Typography variant="body1" style={{fontWeight: 'bold'}}>{displayRole}</Typography>
            }
            <div className={roleClass}>
                <ReactMarkdown className="message-content">{content}</ReactMarkdown>
            </div>
        </div>
    );
}

function MessagesList( {messages} ) {
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const {dots, setDots} = React.useContext(UserContext);
    const {messageIsLoadingDetails, setMessageIsLoadingDetails} = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);

    React.useEffect(() => {
        if (!messageIsLoading) return; // Si el mensaje ya ha sido cargado, no es necesario mostrar los puntos
        const interval = setInterval(() => {
            setDots(prevDots => (prevDots.length < 3 ? prevDots + '.' : '')); // Añadir puntos o resetear
        }, 500); // Intervalo de medio segundo

        return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
    }, [messageIsLoading]);

    const [secondsElapsed, setSecondsElapsed] = React.useState(0);
    const [lastSecondsElapsed, setLastSecondsElapsed] = React.useState(null);

    React.useEffect(() => {
        let interval;
        
        if (messageIsLoading) {
        // Start the interval to update time every 100ms
        interval = setInterval(() => {
            setSecondsElapsed(prev => prev + 0.1); // Increment by 0.1 every 100ms
        }, 100);
        } else {
            // If not processing, clear the timer
            setLastSecondsElapsed(secondsElapsed); // Save last time when processing stops
            setSecondsElapsed(0); // Reset timer when processing stops
        }

        // Clear interval when component unmounts or stops processing
        return () => clearInterval(interval);
    },  [messageIsLoading, currentChatId]);


    return (
        <div className="chat-container">
            <div className="chat" style={{width: '900px', minWidth: '750px'}} >
                {messages.map((chat, index) => (
                    <ChatMessage key={index} role_id={chat.role_id} content={chat.content} lastSecondsElapsed={lastSecondsElapsed}/>
                ))}
                { messageIsLoading &&
                <>
                <Stack direction='row' spacing={2} sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <Stack direction='row' spacing={2} sx={{alignItems: 'center', justifyContent: 'flex-start', width: '100%'}}>
                    <CircularProgress 
                        variant='plain'
                        thickness={1}
                        size="sm"
                        />
                    <Typography variant="body1" style={{textAlign: 'left', marginTop: '1rem'}}>{messageIsLoadingDetails}{dots}</Typography>
                </Stack>
                    <Typography level='body-sm' sx={{whiteSpace: 'nowrap'}}>
                        {secondsElapsed.toFixed(1)} secs
                    </Typography>
                </Stack>
                    <Skeleton
                        variant="rectangular"
                        width={825} // Puedes ajustar el tamaño al que desees
                        height={300}
                        sx={{ borderRadius: '8px', zIndex: -1, marginTop: '1rem' }}
                        />
                </>
                }
            </div>
        </div>
    )
}

function NoMessages( {createChat} ) {
    const [firstMessage, setFirstMessage] = React.useState('');
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);

    return (
        <Card variant='soft' sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '450px', width: '550px', borderRadius: '2rem', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', backgroundColor: 'var(--drawer-background-color)'}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '40%', width: '100%'}}>
                    <Box>
                        <img src={sparkaiLogo} alt="SparkAI_logo" style={{ width: '35px', height: '35px'}} />
                    </Box>
                    <Stack direction='column' spacing={1} sx={{textAlign: 'center', alignItems: 'center'}}>
                        <Typography level='h1' variant="plain">How can I help you today?</Typography>
                        <Typography 
                            sx={{maxWidth: '100%'}}
                            variant="body1">SparkAI is the best AI assistant for your research process. Ask me anything from any topic and I will help you find the information you need.
                        </Typography>
                    </Stack>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '60%', width: '100%'}}>
                    <Stack direction='row' spacing={3} sx={{paddingTop: '2rem'}}>
                        <Box sx={{width: '33%', height: '100%'}}>
                            <Card variant='outline'>
                                <Box sx={{textAlign: 'center'}}>
                                    <MdOutlineBrowserUpdated size={40} />
                                    <Typography variant="h6">Up-to-date scientific literature</Typography>
                                </Box>
                            </Card>
                        </Box>
                        <Box sx={{width: '33%', height: '100%'}}>
                            <Card variant='outline'>
                                <Box sx={{textAlign: 'center'}}>
                                    <VscReferences size={40} />
                                    <Typography variant="h6">Fully referenced citations</Typography>
                                </Box>
                            </Card>
                        </Box>
                        <Box sx={{width: '33%', height: '100%'}}>
                            <Card variant='outline'>
                                <Box sx={{textAlign: 'center'}}>
                                    <BiConversation size={40} />
                                    <Typography variant="h6">Organic conversations</Typography>
                                </Box>
                            </Card>
                        </Box>
                    </Stack>
                    <Box sx={{width: '100%'}}>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            createChat({firstMessage: firstMessage, currentChatId: currentChatId});
                        }}>
                            <Input required
                                variant='plain'
                                sx={{width: '100%', height: '3rem', borderRadius: '1rem'}}
                                value={firstMessage}
                                onChange={(e) => setFirstMessage(e.target.value)}
                                placeholder="Type a message" 
                                disabled={messageIsLoading}
                            />
                        </form>
                    </Box>
                </div>
            </div>
        </Card>
    );
}

export default function ChatBot( {setEnableChatInput} ) {
    const { messages, setMessages } = React.useContext(UserContext);
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const {setMessageIsLoadingDetails } = React.useContext(UserContext);
    const { currentChatId, setCurrentChatId } = React.useContext(UserContext);
    const { currentChatName, setCurrentChatName } = React.useContext(UserContext);
    const { userId } = React.useContext(MainUserContext);

    React.useEffect(() => {
        if (messageIsLoading) {
            // Look for backend task
            axios.get(process.env.REACT_APP_API_URL + '/api/ia/tasks/' + currentChatId)
                .then((response) => {
                    setMessageIsLoadingDetails(response.data.progress + " - " + response.data.status);
                })
                .catch((error) => {
                    console.error('Error fetching task');
                });
        }
    },);


    const createChat = ( {firstMessage} ) => {
        setMessageIsLoading(true);
        axios.post(process.env.REACT_APP_API_URL + '/api/ia/get_chat_name', {
            first_message: firstMessage
        }).then(response => {
            setCurrentChatName(response.data.chat_name);
            axios.post(process.env.REACT_APP_API_URL + '/api/chats', {
                conversation_name: response.data.chat_name,
                user_id: userId,
                first_message: firstMessage
            }).then(response => {
                const chatId = response.data.chat_id;
                setCurrentChatId(chatId);
                // setMessageIsLoadingDetails("Looking for the most relevant scientific papers")
                axios.post(process.env.REACT_APP_API_URL + '/api/ia/call', {
                    query: firstMessage,
                    chat_history: messages,
                    conversation_id: chatId
                }).then(response => {
                    // setMessageIsLoadingDetails("Typing")
                    axios.post(process.env.REACT_APP_API_URL + '/api/messages', {
                        conversation_id: chatId,
                        role_id: 2,
                        message: response.data.text
                    }).then(response => {
                        axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + chatId)
                            .then((response) => {
                                setMessageIsLoading(false);
                                setMessages(response.data);
                                setMessageIsLoadingDetails("");
                            })
                            .catch((error) => {
                                console.error('Error fetching messages');
                            });
                    }).catch(error => {
                        console.error('Error creating message');
                    });
                }).catch(error => {
                    console.error('Error refining query');
            });
            }).catch(error => {
                console.error('Error creating chat');
            });
        }).catch(error => {
            console.error('Error getting chat name');
            setMessageIsLoading(false);
            setMessageIsLoadingDetails("");
        });
    }

    const messagesLength = messages.length;
    React.useEffect(() => {

        if (messagesLength > 0) {
            setEnableChatInput(true);
        } else {
            setEnableChatInput(false);
        }
    }, [messages]);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + currentChatId)
            .then((response) => {
                setMessages(response.data);
            })
            .catch((error) => {
                console.error('Error fetching messages:', error);
            });
    }, [currentChatId]);
    return (
        <>
        {messagesLength > 0 ? <MessagesList messages={messages} /> : <NoMessages createChat={createChat}/>}
        </>
    );
}