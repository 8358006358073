import React from "react";
import { Container, Grid, Box, Avatar, Typography, Button, Card, CardContent, Menu, MenuItem, Stack } from "@mui/joy";
import { AccountCircle, CalendarToday, Language, Email, Public, Money, Lock } from "@mui/icons-material";
import { MainUserContext } from "../App";
import axios from "axios";

import { Helmet } from 'react-helmet';

import { useNavigate } from 'react-router-dom';


const PersonalInfo = ({ user }) => {
    return (
        <Grid item xs={12} md={8}>
        <Box>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
            Personal Information
            </Typography>
            <Typography color="textSecondary" sx={{ mb: 4 }}>
            Manage your personal information, including phone numbers and email addresses where you can be contacted
            </Typography>

            <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Card>
                <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <AccountCircle color="primary" sx={{ mr: 2 }} />
                    <Typography variant="subtitle1" fontWeight="bold">
                        Name
                    </Typography>
                    </Box>
                    <Typography>{(user.name == null) ? 'Not specified' : user.name + " " + user.surname}</Typography>
                </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Card>
                <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <CalendarToday color="primary" sx={{ mr: 2 }} />
                    <Typography variant="subtitle1" fontWeight="bold">
                        Date of Birth
                    </Typography>
                    </Box>
                    <Typography>{(user.dateOfBirth == null) ? 'Not specified' : user.dateOfBirth}</Typography>
                </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card>
                <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Email color="primary" sx={{ mr: 2 }} />
                    <Typography variant="subtitle1" fontWeight="bold">
                        Contactable at
                    </Typography>
                    </Box>
                    <Typography>{user.mail}</Typography>
                </CardContent>
                </Card>
            </Grid>
            </Grid>
        </Box>
        </Grid>
    );
}

const PlansBilling = ({ user }) => {
    return (
        <Grid item xs={12} md={8}>
        <Box>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
            Plans & Billing
            </Typography>
            <Typography color="textSecondary" sx={{ mb: 4 }}>
            Manage your subscription and billing information here.
            </Typography>

            <Grid item xs={12}>
                <Card>
                <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Money color="primary" sx={{ mr: 2 }} />
                    <Typography variant="subtitle1" fontWeight="bold">
                        Current plan
                    </Typography>
                    </Box>
                    <Typography>{(user.subscribed) ? 'Premium' : 'Free (Beta testing)'}</Typography>
                </CardContent>
                </Card>
            </Grid>
        </Box>
        </Grid>
    );
}

const Security = ({ user }) => {
    return (
        <Grid item xs={12} md={8}>
        <Box>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
            Security
            </Typography>
            <Typography color="textSecondary" sx={{ mb: 4 }}>
            Change your password and other security settings here.
            </Typography>

            <Grid item xs={12}>
                <Card>
                <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Lock color="primary" sx={{ mr: 2 }} />
                    <Typography variant="subtitle1" fontWeight="bold">
                        Change password
                    </Typography>
                    </Box>
                    <Typography>************</Typography>
                </CardContent>
                </Card>
            </Grid>
        </Box>
        </Grid>
    );
}

const SidebarMenu = ( { personalInfoSelected, setPersonalInfoSelected, plansSelected, setPlansSelected, securitySelected, setSecuritySelected } ) => {

    return (
        <Box>
            <Stack direction="column" spacing={2} sx={{ mt: 4 }}>
                <Button
                    variant={personalInfoSelected ? "contained" : "soft"}
                    onClick={() => {
                        setPersonalInfoSelected(true);
                        setPlansSelected(false);
                        setSecuritySelected(false);
                    }}
                >
                    Personal Information
                </Button>
                <Button
                    onClick={() => {
                        setPersonalInfoSelected(false);
                        setPlansSelected(true);
                        setSecuritySelected(false);
                    }}
                    variant={plansSelected ? "contained" : "soft"}
                >
                    Plans & Billing
                </Button>
                <Button
                    onClick={() => {
                        setPersonalInfoSelected(false);
                        setPlansSelected(false);
                        setSecuritySelected(true);
                    }}
                    variant={securitySelected ? "contained" : "soft"}
                >
                    Security
                </Button>
            </Stack>
        </Box>
    );
  };

const Account = () => {
    const [user, setUser] = React.useState({});
    const { userId } = React.useContext(MainUserContext);
    const { loggedIn, setLoggedIn } = React.useContext(MainUserContext);

    const [ personalInfoSelected, setPersonalInfoSelected ] = React.useState(true);
    const [ plansSelected, setPlansSelected ] = React.useState(false);
    const [ securitySelected, setSecuritySelected ] = React.useState(false);

    const logout = () => {
        setLoggedIn(false);
    }

    React.useEffect(() => {
        if (!loggedIn) {
            navigate('/signin');
        }
      }, [loggedIn]);

    const navigate = useNavigate();

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/users/' + userId).then((response) => {
            setUser(response.data[0]);
        });
    }, [userId]);

    return (
        <Container maxWidth="md">
        <Helmet>
            <title>EderSpark Account</title>
        </Helmet>
        <Box
            sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
            mt: 4,
            }}
        >
            <Typography variant="h4" fontWeight="bold">
            EderSpark Account
            </Typography>
            <Stack direction="row" spacing={2}>
                <Button 
                    onClick={() => {
                        navigate('/chat');
                    }}
                variant="soft" color="primary">
                Go back to chat
                </Button>
                <Button variant="outlined" color="warning"
                    onClick={logout}
                >
                Sign out
                </Button>
            </Stack>
        </Box>
        <Grid container spacing={4}>
            {/* Left Sidebar */}
            <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Avatar sx={{ width: 80, height: 80 }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                {(user.name == null) ? 'Not specified' : user.name + " " + user.surname}
                </Typography>
                <Typography color="textSecondary">{user.mail}</Typography>

                <SidebarMenu
                    personalInfoSelected={personalInfoSelected}
                    plansSelected={plansSelected}
                    securitySelected={securitySelected} 
                    setPersonalInfoSelected={setPersonalInfoSelected} 
                    setPlansSelected={setPlansSelected}
                    setSecuritySelected={setSecuritySelected} />
            </Box>
            </Grid>
            {(personalInfoSelected) ? <PersonalInfo user={user} /> : null}
            {(plansSelected) ? <PlansBilling user={user} /> : null}
            {(securitySelected) ? <Security user={user} /> : null}
        </Grid>
        </Container>
    );
    };

    export default Account;
